import * as Sentry from '@sentry/react';
import axios from 'axios';
import GA4React from 'ga-4-react';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Spinner from '../components/Spinner/Spinner';
import { requestForToken } from '../helpers/firebase';
import Dashboard from '../layout/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import NotFoundPage from '../pages/Notfound/NotFoundPage';
import Otp from '../pages/Otp/Otp';
import SelectAccount from '../pages/SelectAccount/SelectAccount';
import SignUpForm from '../pages/SignUp/SignUp';
import ProtectedRoute from '../routes/protectedRoute';
import axiosInstance from '../services/axiosInstance';
import './App.css';

const ga4react = new GA4React(`${process.env.REACT_APP_GMT_KEY}`);
ga4react.initialize();

function App() {
	// const { selectedAccount } = useSelector((state) => state.account);

	const [flag, setFlag] = useState(false);
	const [accountFlag, setAccountFlag] = useState(false);
	const [loading, setLoading] = useState(false);
	const [, setTokenFound] = useState(false);

	useEffect(() => {
		setLoading(true);
		const nationalId = secureLocalStorage.getItem('query');
		const selectedAccount = secureLocalStorage.getItem('selectedAccount');
		const token = secureLocalStorage.getItem('token');

		window.dataLayer.push({
			userId:
				secureLocalStorage.getItem('query') === null
					? 'Anonymous'
					: secureLocalStorage.getItem('query'),
			event: 'userIdSet',
		});

		axios.get('https://ipapi.co/json/').then((res) => {
			axiosInstance.post('v1/gps-logger', {
				accountNumber: nationalId || '0',
				countryName: res.data.country_name ? res.data.country_name : 'null',
				city: res.data.region ? res.data.region : 'null',
				region: res.data.region ? res.data.region : 'null',
				latitude: res.data.latitude ? res.data.latitude.toString() : 0.0,
				longitude: res.data.longitude ? res.data.longitude.toString() : 0.0,
			});
		});

		if (token) {
			axiosInstance.defaults.headers.Authorization = `Bearer ${secureLocalStorage.getItem(
				'token',
			)}`;
			axiosInstance
				.get(`v1/jwttoken/validation`)
				.then((res) => {
					setFlag(res.data.headers.status);
					if (selectedAccount) setAccountFlag(true);
				})
				.catch(() => {
					setFlag(false);
				})
				.finally(() => setLoading(false));
		} else {
			setFlag(false);
			setLoading(false);
		}
	}, []);

	requestForToken(setTokenFound); // adding Firebase Token

	return (
		<>
			<Toaster
				position='top-right'
				gutter={10}
				containerClassName='p-5'
				containerStyle={{ padding: '20px' }}
				toastOptions={{
					duration: 5000,

					style: {
						padding: '10px 50px',
						fontWeight: '600',
					},
				}}
			/>
			{loading ? (
				<div className='vh-100 d-flex justify-content-center'>
					<Spinner />
				</div>
			) : (
				<Router>
					<div className='App'>
						<Switch>
							<Route exact path='/signup' component={SignUpForm} />

							<Route
								exact
								path='/'
								render={() => (flag ? <Redirect to='/accounts' /> : <Login />)}
							/>

							<Route
								exact
								path='/accounts'
								render={() =>
									accountFlag ? <Redirect to='/dashboard' /> : <SelectAccount />
								}
							/>

							<ProtectedRoute path='/dashboard' component={Dashboard} />

							<Route
								exact
								path='/otp'
								render={() => (flag ? <Redirect to='/accounts' /> : <Otp />)}
							/>

							<ProtectedRoute exact path='/logout' component={Logout} />

							<Route path='*' component={NotFoundPage} />
						</Switch>
					</div>
				</Router>
			)}
		</>
	);
}

export default Sentry.withProfiler(App);
