import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	soilTest: {
		isTestDone: false,
	},
};

const soilTestSlice = createSlice({
	name: 'soilTest',
	initialState,
	reducers: {
		setSoilTest: (state, action) => {
			state.soilTest = action.payload;
		},
	},
});

const { reducer, actions } = soilTestSlice;

export const { setSoilTest } = actions;

export default reducer;
