import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Api from './Api';

const axiosInstance = axios.create({
	baseURL: Api,
	headers: {
		Authorization: `Bearer ${secureLocalStorage.getItem('token')}`,
		'Content-Type': 'application/json',
	},
});

export default axiosInstance;
