import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HandPic from '../../assets/Icons/icon_hello.png';

const DashboardHeader = () => {
	const { t } = useTranslation();
	const { accountDetails } = useSelector((state) => state.account);

	const date = new Date();
	const hour = date.getHours();

	return (
		<div className='d-flex justify-content-center flex-fill align-items-center gap-3 '>
			<img src={HandPic} alt='Hi,' />
			<div
				className='d-flex align-items-center gap-2'
				data-testid='welcome-msg'
				style={{
					color: '#FFFFFF',
					fontSize: '18px',
					fontWeight: '400',
				}}>
				<div>
					{hour >= 12 ? (
						hour >= 16 ? (
							<span>{t('good.evening')}, </span>
						) : (
							<span>{t('good.afternoon')}, </span>
						)
					) : (
						<span>{t('good.morning')}, </span>
					)}
					{accountDetails?.customerName}
				</div>
			</div>
		</div>
	);
};

export default DashboardHeader;
