import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLocationEnabled: false,
	lat: null,
	lng: null,
	forecast: [],
	current: {},
};

const weatherSlice = createSlice({
	name: 'weather',
	initialState,
	reducers: {
		setIsLocationEnabled: (state, action) => {
			state.isLocationEnabled = action.payload;
		},
		setLat: (state, action) => {
			state.lat = action.payload;
		},
		setLng: (state, action) => {
			state.lng = action.payload;
		},
		setForecast: (state, action) => {
			state.forecast = action.payload;
		},
		setCurrent: (state, action) => {
			state.current = action.payload;
		},
	},
});

const { reducer, actions } = weatherSlice;

export const { setIsLocationEnabled, setLat, setLng, setForecast, setCurrent } =
	actions;

export default reducer;
