import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';

const initialState = {
	accountDetails: null,
	paymentDetails: null,
	deviceList: [],
	selectedAccount: '',
	selfRegistrationData:
		JSON.parse(secureLocalStorage.getItem('selfRegistrationData')) || {},
	accountRef: '',
};

const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setAccountDetails: (state, action) => {
			state.accountDetails = action.payload;
		},
		setPaymentDetails: (state, action) => {
			state.paymentDetails = action.payload;
		},
		setSelectedAccount: (state, action) => {
			state.selectedAccount = action.payload;
		},
		setDeviceList: (state, action) => {
			state.deviceList = action.payload;
		},
		setSelfRegistrationData: (state, action) => {
			state.selfRegistrationData = action.payload;
		},
		setAccountRef: (state, action) => {
			state.accountRef = action.payload;
		},
	},
});

const { reducer, actions } = accountSlice;

export const {
	setAccountDetails,
	setPaymentDetails,
	setSelectedAccount,
	setDeviceList,
	setSelfRegistrationData,
	setAccountRef,
} = actions;

export default reducer;
