import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Logo from '../../components/Logo/Logo';
import Colors from '../../helpers/AppColors';
import { authMenu } from '../../helpers/menu';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import LoginFooter from '../Login/LoginFooter';
import LoginHeader from '../Login/LoginHeader';
import OtpInput from './OtpInput';

const Otp = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [show, setShow] = useState(true);

	// useEffect(() => {
	// 	if (secureLocalStorage.getItem('query') === null) {
	// 		history.push('/');
	// 	}
	// 	window.addEventListener('beforeunload', handleBeforeUnload);
	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// });

	React.useEffect(() => {
		if (!secureLocalStorage.getItem('query')) {
			history.replace('/');
		}
		return () => {
			setShow(false);
		};
	}, []);

	// const handleBeforeUnload = (e) => {
	// 	e.preventDefault();
	// 	const message = t('label.confirm.reload');
	// 	e.returnValue = message;
	// 	secureLocalStorage.clear();
	// 	return message;
	// };

	return (
		<PageWrapper title={authMenu.otp.text} bgcolor={Colors.White}>
			<Page className='d-flex justify-content-center align-items-center m-0 p-0 py-sm-5 vh-100'>
				<div
					className='d-flex flex-column justify-content-between col-12 col-sm-8 col-lg-6 p-3 rounded shadow bg-white'
					style={{ minHeight: '100%' }}>
					<LoginHeader />
					<div className='d-flex col-12 align-items-center flex-column'>
						<Logo />
						<div className='d-flex flex-column align-items-center'>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '22px',
									color: Colors.Black,
								}}>
								{t('otp.verify')}
							</span>
							<span
								style={{
									textAlign: 'center',
									fontWeight: '400',
									fontSize: '15px',
									color: Colors.Black,
									marginTop: '10px',
								}}>
								{show
									? t('otp.description') + secureLocalStorage.getItem('msisdn')
									: 'Sorry, OTP Time Expired'}
							</span>
							{!show && (
								<button
									onClick={() => {
										secureLocalStorage.setItem('query', 'null');
										history.replace('/');
									}}
									style={{
										background: Colors.pank,
										borderRadius: '12px',
										color: Colors.White,
									}}
									type='button'
									className='btn px-5 py-2 my-2'>
									{t('label.retry')}
								</button>
							)}
						</div>
						{show && <OtpInput show={show} setShow={setShow} />}
					</div>
					<LoginFooter />
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Otp;
