import i18n from './i18n';

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'menu.dashboard',
		path: '/dashboard',
		icon: 'grid_view',
		subMenu: null,
		isBeta: false,
		isProd: true,
	},
	accountInfo: {
		id: 'accounts',
		text: 'menu.account',
		path: '/dashboard/accountinfo',
		icon: 'person_outline',
		subMenu: null,
		isBeta: false,
		isProd: true,
	},
	verifyDevice: {
		id: 'editWizard',
		text: 'menu.device',
		path: '/dashboard/verify-devices',
		icon: 'tv',
		isBeta: false,
		isProd: true,
	},
	referrals: {
		id: 'referrals',
		text: 'menu.referral',
		path: '/dashboard/referral',
		icon: 'people_outline',
		isBeta: false,
		isProd: true,
	},
	tickets: {
		id: 'tickets',
		text: 'menu.ticket',
		path: '/dashboard/mytickets',
		icon: 'phone',
		subMenu: null,
		isBeta: false,
		isProd: true,
	},
};

export const Logout = {
	logout: {
		id: 'logout',
		text: 'menu.logout',
		path: '/logout',
		icon: 'Logout',
		subMenu: null,
		isBeta: false,
		isProd: true,
	},
};

export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		icon: 'Extension',
	},

	otp: {
		id: 'otp',
		text: 'OTP',
		path: '/otp',
		icon: 'Extension',
	},
	signup: {
		id: 'signup',
		text: 'Signup',
		path: '/signup',
		icon: 'Extension',
	},
	selectAccount: {
		id: 'selectAccount',
		text: 'Select Device',
		path: '/accounts',
		icon: 'Extension',
	},
};

export const referralsHeaders = [
	{
		name: 'Referral ID',
	},
	{
		name: 'Name',
	},
	{
		name: 'Phone Number',
	},
	{
		name: 'Location',
	},
	{
		name: 'Product Interested',
	},
	{
		name: 'Status',
	},
	{
		name: 'Date Created',
	},
];

export const paymentHeaders = [
	{
		name: 'Payment Method',
	},
	{
		name: 'Type',
	},
	{
		name: 'Amount',
	},
	{
		name: 'Payment Reference',
	},
	{
		name: 'Date',
	},
];

export const weatherHeaders = [
	{
		name: '',
	},
	{
		name: '',
	},
];

// disposition headers

export const dipositionHeaders = [
	{
		name: i18n.t('reason.header'),
	},
	{
		name: i18n.t('more.header'),
	},
];
