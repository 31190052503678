import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../helpers/AppColors';

const Header = ({ children }) => {
	return (
		<header
			className='col-12 d-flex justify-content-center'
			style={{
				position: 'sticky',
				top: 0,
				backgroundColor: Colors.Green,
				borderRadius: '0px 0px 16px 16px',
				zIndex: 1020,
			}}>
			<div className='flex-fill my-4 text-center'>{children}</div>
		</header>
	);
};
Header.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Header;
