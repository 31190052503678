import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ThemeContext from '../../contexts/themeContext';
import Aside from '../Aside/Aside';
import Wrapper from '../Wrapper/Wrapper';

function Dashboard() {
	const location = useLocation();

	useContext(ThemeContext);

	return (
		<Switch location={location}>
			<Route>
				<div className='d-flex vh-100 flex-column-reverse flex-sm-row'>
					<Aside />
					<Wrapper />
				</div>
			</Route>
		</Switch>
	);
}

export default Dashboard;
