import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageNotFound from '../../assets/Icons/PageNotFound.svg';
import './NotFound.css';

const NotFoundPage = () => {
	const { t } = useTranslation();

	return (
		<div className='container'>
			<div className='header1'>
				<h4>{t('not.found.page.title')}</h4>
			</div>
			<div className='img1'>
				<img
					src={PageNotFound}
					style={{ pointerEvents: 'none' }}
					height='100%'
					width='100%'
					alt={t('not.found.page.title')}
				/>
			</div>
			<div>
				<p className='img__footer'>
					<Link to='/' className='footer__link'>
						{t('go-back-btn')}
					</Link>
				</p>
			</div>
		</div>
	);
};

export default NotFoundPage;
