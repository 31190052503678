import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Colors from '../../helpers/AppColors';
import axiosInstance from '../../services/axiosInstance';
import { setSelfRegistrationData } from '../../slices/Account/accountSlice';
import OtpTimer from './OtpTimer';

const OtpInput = ({ show, setShow }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState('');
	const [message, setMessage] = useState('');
	const [disable, setDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });

	const handleOnClick = (e) => {
		setLoading(true);
		e.preventDefault();
		const fullOtp = otp.otp1.concat(otp.otp2, otp.otp3, otp.otp4);
		if (fullOtp === '') {
			setMessage(i18n.t('please.input.otp'));
			setError(true);
			setSuccess(false);
			setLoading(false);
		} else if (fullOtp.length !== 4) {
			setMessage(i18n.t('valid.input.otp'));
			setError(true);
			setSuccess(false);
			setLoading(false);
		} else {
			setDisabled(true);
			setLoading(true);
			const json = {
				query: secureLocalStorage.getItem('query'),
				code: fullOtp,
			};
			const accounts = [];
			axiosInstance
				.post(`v1/customer/verify-otp`, json)
				.then((res) => {
					if (res.data.headers.status) {
						secureLocalStorage.setItem('isAuthenticated', true);
						setMessage(i18n.t('otp.success'));
						setError(false);
						setSuccess(true);
						secureLocalStorage.setItem(
							'query',
							secureLocalStorage.getItem('query'),
						);
						secureLocalStorage.setItem('token', res.data.body.token);
						secureLocalStorage.setItem('customerID', res.data.body.data.id);
						res.data.body.data.accounts.map((item) => {
							if (item.selfRegistrationEnabled === true) {
								secureLocalStorage.setItem(
									'selfRegistrationData',
									JSON.stringify({
										selfRegistrationEnabled: true,
										selfRegisterAcc: item.id,
										selfRegisterAccRef: item.accountRef,
									}),
								);
								dispatch(
									setSelfRegistrationData({
										selfRegistrationEnabled: true,
										selfRegisterAcc: item.id,
									}),
								);
							}
							accounts.push({
								id: item.id,
								Product_Name: item.productName,
								Account_Ref: item.accountRef,
								Status: item.status,
							});
						});
						secureLocalStorage.setItem(
							'userDetails',
							JSON.stringify({
								totalAccounts: res.data.body.data.accounts.length,
								accounts,
							}),
						);

						// setting default authorization token in headers
						axiosInstance.defaults.headers.Authorization = `Bearer ${res.data.body.token}`;

						setTimeout(() => {
							setLoading(false);
							setDisabled(false);
							if (res.data.body.totalAccounts === 1) {
								secureLocalStorage.setItem(
									'selectedAccount',
									res.data.body.accounts[0].id,
								);
								history.replace(`/dashboard`);
							} else {
								history.replace(`/accounts`);
							}
						}, 2000);
					} else {
						setError(true);
						setSuccess(false);
						setDisabled(false);
						setMessage(i18n.t('invalid.input.otp'));
						secureLocalStorage.clear();
						setTimeout(() => {
							setLoading(false);
							window.location.reload();
						}, 1500);
					}
				})
				.catch(() => {
					setError(true);
					setDisabled(false);
					setSuccess(false);
					setMessage(i18n.t('invalid.input.otp'));
					setTimeout(() => {
						setLoading(false);
						secureLocalStorage.clear();
						window.location.reload();
					}, 2000);
				});
		}
	};

	const handleChange = (value1, event) => {
		setOtp({ ...otp, [value1]: event.target.value });
	};

	const inputfocus = (elmnt) => {
		if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
			const next = elmnt.target.tabIndex - 2;
			if (next > -1) {
				elmnt.target.form.elements[next].focus();
			}
		} else {
			const next = elmnt.target.tabIndex;
			if (next >= 4) {
				setDisabled(false);
			}
			if (next < 5) {
				elmnt.target.form.elements[next].focus();
			}
		}
	};

	useEffect(() => {
		setShow(true);
		return () => {
			setShow(false);
		};
	});

	return (
		<form
			onSubmit={handleOnClick}
			className='d-flex flex-column row w-100 align-items-center my-2'>
			{show && (
				<div
					className='my-2'
					data-testid='timer'
					style={{
						fontWeight: '600',
						fontSize: '13px',
						textAlign: 'center',
						color: Colors.Green,
					}}>
					<OtpTimer setShow={setShow} />
				</div>
			)}
			<div className='d-flex my-4 w-100 justify-content-center align-items-center gap-3'>
				{Object.keys(otp).map((item, index) => {
					return (
						<input
							key={index}
							style={{
								margin: 0,
								display: 'flex',
								width: '50px',
								height: '55px',
								textAlign: 'center',
								border: '1px solid rgba(20, 20, 20, 0.12)',
								background: 'rgba(37, 37, 67, 0.04)',
							}}
							name={`otp${index + 1}`}
							type='text'
							inputMode='numeric'
							autoComplete='off'
							value={otp[item]}
							onChange={(e) => handleChange(item, e)}
							tabIndex={index + 1}
							maxLength='1'
							onFocus={(e) => (otp[item] !== '' ? e.target.select() : null)}
							onKeyUp={(e) => inputfocus(e)}
						/>
					);
				})}
			</div>
			{error && (
				<div className='mb-3 text-center' style={{ color: Colors.pank }}>
					{message}
				</div>
			)}
			{success && (
				<div className='mb-3 text-center' style={{ color: Colors.Green }}>
					{message}
				</div>
			)}

			<button
				style={{
					background: Colors.Brand,
					borderRadius: '12px',
					color: Colors.White,
				}}
				type='submit'
				disabled={
					otp.otp1 === '' ||
					otp.otp2 === '' ||
					otp.otp3 === '' ||
					otp.otp4 === ''
						? true
						: disable || loading === true
				}
				className='btn col-sm-10 col-md-8 col-xs-12 py-3'>
				{loading ? (
					<div
						className='spinner-border spinner-border-sm text-light '
						role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
				) : (
					t('otp.login')
				)}
			</button>
		</form>
	);
};

export default OtpInput;
