import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Logo from '../../components/Logo/Logo';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Colors from '../../helpers/AppColors';
import { validateInput } from '../../helpers/helpers';
import { authMenu } from '../../helpers/menu';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import axiosInstance from '../../services/axiosInstance';
import LoginFooter from './LoginFooter';
import LoginHeader from './LoginHeader';
import Loader from '../../components/Spinner/Loader';

const Login = () => {
	const history = useHistory();
	const { t, i18n } = useTranslation();

	const [query, setQuery] = useState('');
	const [error, setError] = useState(false);
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState('');
	const [loading, setLoading] = useState(false);

	const handleOnClick = (event) => {
		event.preventDefault();
		if (validateInput(query) === false) {
			setMessage(i18n.t('enter.valid.id'));
			setError(true);
			setSuccess(false);
		} else {
			setLoading(true);
			const json = {
				query,
				firebaseToken: secureLocalStorage.getItem('firebaseToken'),
			};
			axiosInstance
				.post(`v2/customer/login`, json)
				.then((res) => {
					if (res.data.headers.status) {
						setMessage(i18n.t('login.success'));
						setError(false);
						setSuccess(true);
						secureLocalStorage.setItem('query', query);
						secureLocalStorage.setItem('msisdn', res.data.body.msisdn);
						setTimeout(() => {
							history.replace(`/otp`);
						}, 1000);
						setLoading(false);
					} else {
						setError(true);
						setSuccess(false);
						setLoading(false);
						setMessage(i18n.t('login.no.account'));
					}
				})
				.catch(() => {
					setError(true);
					setSuccess(false);
					setLoading(false);
					setMessage(i18n.t('login.no.account'));
				});
		}
	};

	return (
		<PageWrapper title={authMenu.login.text} bgcolor={Colors.White}>
			<Page className='d-flex justify-content-center align-items-center m-0 p-0 py-sm-5 vh-100'>
				<div
					className='d-flex flex-column justify-content-between col-12 col-sm-8 col-lg-6 py-3 px-4 rounded shadow bg-white'
					style={{ minHeight: '100%' }}>
					<LoginHeader />
					<div className='d-flex col-12 align-items-center flex-column'>
						<Logo />
						<div className='d-flex flex-column align-items-center'>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '22px',
									color: Colors.Black,
								}}>
								{t('login.title')}
							</span>
							<span
								style={{
									textAlign: 'center',
									fontWeight: '400',
									fontSize: '15px',
									color: Colors.Black,
									marginTop: '10px',
								}}>
								{t('login.description')}
							</span>
						</div>
						<form
							onSubmit={handleOnClick}
							className='d-flex flex-column col-12 my-2 align-items-center row g-4'>
							<div className='col-sm-10 col-md-8 col-xs-12'>
								<FormGroup
									id='nationalId'
									name='nationalId'
									isFloating
									label={t('login.form.input')}>
									<Input
										id='nationaID'
										value={query}
										autoFocus
										type='number'
										onChange={(e) => {
											setQuery(e.target.value);
										}}
									/>
								</FormGroup>
								{error && (
									<div
										className='mt-2 text-center'
										style={{ color: Colors.pank }}>
										{message}
									</div>
								)}
								{success && (
									<div
										className='mt-2 text-center'
										style={{ color: Colors.Green }}>
										{message}
									</div>
								)}
								<button
									disabled={query === '' || loading === true}
									style={{
										background: Colors.Brand,
										borderRadius: '12px',
										color: Colors.White,
									}}
									type='submit'
									className='btn mt-3 w-100 py-3'>
									{loading ? (
										<Loader color={Colors.White} size='1rem' />
									) : (
										t('otp.login')
									)}
								</button>
							</div>
						</form>
						<div
							className='mt-2 p-2'
							style={{
								color: Colors.Black,
							}}>
							{t('login.no.account.button')}{' '}
							<Link
								to='/signup'
								style={{
									outline: 'none',
									color: Colors.Green,
									textDecoration: 'none',
								}}>
								{t('login.join.suncculture')}
							</Link>
						</div>
					</div>
					<LoginFooter />
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
