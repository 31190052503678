import i18n from './i18n';

export const ticketReason = [
	{
		groupId: 'Customer Support',
		subject: i18n.t('customer.enquiry'),
	},
	{
		groupId: 'Customer Support',
		subject: i18n.t('label.complaint'),
	},
	{
		groupId: 'PAYG',
		subject: i18n.t('payment.issue'),
	},
	{
		groupId: 'Customer Support',
		subject: i18n.t('technical.issue'),
	},
	{
		groupId: 'Sales',
		subject: i18n.t('device.upgrade'),
	},
];

export const TicketsTabsData = [
	{ title: 'All' },
	{ title: 'Pending' },
	{ title: 'Done' },
];

export const ticketsHeaders = {
	id: 'ID',
	reason: 'Reason',
	description: 'Description',
	date: 'Date Created',
	status: 'Status',
};

export const productOfInterest = [
	{
		value: 'Climate Smart Battery',
	},
	{
		value: 'Climate Smart Direct',
	},
	{
		value: 'RainMaker2C Kubwa',
	},
	{
		value: 'RainMaker2 with ClimateSmart Battery™',
	},
];

export const dipositionData = [
	{
		name: i18n.t('customer.enquiry'),
		moreInfo: i18n.t('enquiry.info'),
	},
	{
		name: i18n.t('label.complaint'),
		moreInfo: i18n.t('complaint.info'),
	},
	{
		name: i18n.t('payment.issue'),
		moreInfo: i18n.t('payment.more-info'),
	},
	{
		name: i18n.t('technical.issue'),
		moreInfo: i18n.t('technical.info'),
	},
	{
		name: i18n.t('device.upgrade'),
		moreInfo: i18n.t('device.info'),
	},
];

export const waterSources = [
	{
		key: 'Dam',
		value: i18n.t('label.dam'),
	},
	{
		key: 'River',
		value: i18n.t('label.river'),
	},
	{
		key: 'Shallow water',
		value: i18n.t('label.shallow.water'),
	},
	{
		key: 'Lake',
		value: i18n.t('label.lake'),
	},
	{
		key: 'Rain',
		value: i18n.t('label.rain'),
	},
	{
		key: 'Rain Water harvest',
		value: i18n.t('label.rain.water.harvest'),
	},
];

export const purchaseDates = [
	{
		key: 'Now',
		value: i18n.t('label.now'),
	},
	{
		key: 'Two Weeks',
		value: i18n.t('label.two.weeks'),
	},
	{
		key: 'Two Months',
		value: i18n.t('label.two.months'),
	},
	{
		key: 'Later',
		value: i18n.t('label.later'),
	},
];

export const BooleanData = [
	{
		key: true,
		value: i18n.t('label.yes'),
	},
	{
		key: false,
		value: i18n.t('label.no'),
	},
];

export const FarmOwnerShipOptions = [
	{ key: 'Self-owned', value: 'Self owned' },
	{ key: 'Rented', value: 'Rented' },
	{ key: 'Leased', value: 'Leased' },
	{ key: 'Partnership', value: 'Partnership' },
];

export const FarmManagementOptions = [
	{ key: 'self-managed', value: 'Self managed' },
	{ key: 'farm-manager', value: 'Farm Manager' },
];
