import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../helpers/AppColors';

const LoginFooter = () => {
	const { t } = useTranslation();

	return (
		<div className='d-flex col-12 justify-content-between '>
			<div style={{ color: 'black' }}>
				USSD: <span style={{ color: Colors.Green }}>*384*02#(KE)</span>
			</div>
			<div style={{ color: 'black' }}>
				{t('call.toll')}:{' '}
				<span style={{ color: Colors.Green }}>0800721042</span>
			</div>
		</div>
	);
};

export default LoginFooter;
