import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import secureLocalStorage from 'react-secure-storage';

function ProtectedRoute({ component: Component, ...restOfProps }) {
	const isAuthenticated = secureLocalStorage.getItem('isAuthenticated');
	return (
		<Route
			{...restOfProps}
			render={(props) =>
				isAuthenticated ? <Component {...props} /> : <Redirect to='/' />
			}
		/>
	);
}

ProtectedRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
