import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import kenya from '../../assets/Icons/icon_Kenya.png';
import uk from '../../assets/Icons/icon_united kingdom.png';
import Colors from '../../helpers/AppColors';
import { changeLanguage } from '../../helpers/helpers';

const LoginHeader = () => {
	const KENYA = 'SW';
	const UK = 'EN';
	const [flagSelected, setFlagSelected] = useState('en');

	const LanguageSelectButton = (name, flagName) => {
		return (
			<div
				onClick={() => changeLanguage(name)}
				className='d-flex flex-row justify-content-center align-items-center gap-2 p-3 rounded-0'
				style={{
					width: '58px',
					height: '36px',
					border:
						flagSelected === name.toLowerCase()
							? '1px solid rgba(20, 20, 20, 0.12)'
							: '',
					cursor: 'pointer',
				}}>
				<img alt={flagName} src={flagName} />
				<div
					style={{
						width: '16px',
						height: '20px',
						fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: '400',
						color: Colors.Black,
					}}>
					{name}
				</div>
			</div>
		);
	};

	useEffect(() => {
		const lang = secureLocalStorage.getItem('lang');
		setFlagSelected(lang || 'en');
	}, []);

	return (
		<div className='d-flex col-12 flex-row justify-content-between'>
			{/* {page !== 'SignUP' && (
				<div
					className='d-flex flex-row align-items-center '
					onClick={() => {
						linkToTrainingPage();
					}}
					style={{ cursor: 'pointer' }}>
					<img src={iconVideoLesson} alt={t('header.take.training')} />
					<div
						className='fw-bold'
						style={{
							color: Colors.Green,
							fontFamily: 'Poppins',
							fontWeight: '500',
							fontSize: '13px',
						}}>
						{t('header.take.training')}
					</div>
				</div>
			)} */}
			<div className='d-flex gap-2 w-100 justify-content-end'>
				{LanguageSelectButton(UK, uk)}
				{LanguageSelectButton(KENYA, kenya)}
			</div>
		</div>
	);
};

export default LoginHeader;
