import React from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import { useHistory } from 'react-router-dom';
import LoginHeader from '../Login/LoginHeader';
import { authMenu } from '../../helpers/menu';
import Colors from '../../helpers/AppColors';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import LoginFooter from '../Login/LoginFooter';
import Logo from '../../components/Logo/Logo';
import axiosInstance from '../../services/axiosInstance';
import Loader from '../../components/Spinner/Loader';

const SelectAccount = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const history = useHistory();
	const [userAccounts, setuserAccounts] = React.useState([]);

	const fetchCustomerAccounts = async () => {
		setLoading(true);
		const accountData = [];
		await axiosInstance
			.get(`v1/customer/accounts`)
			.then(async (response) => {
				if (response.data.body.accounts.length > 0) {
					response.data.body.accounts.map((data) => {
						accountData.push({
							id: data.id,
							Product_Name: data.productName,
							Account_Ref: data.accountRef,
							Status: data.status,
						});
					});
				}
				setuserAccounts(accountData);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const handleItemClick = (item, index) => {
		if (selectedItem === null) {
			setSelectedItem({ item, index });
		} else if (selectedItem.index === index) {
			setSelectedItem(null);
		} else {
			setSelectedItem({ item, index });
		}
	};

	const handleContinue = () => {
		secureLocalStorage.setItem('selectedAccount', selectedItem.item.id);
		history.replace('/dashboard');
	};

	React.useEffect(() => {
		if (secureLocalStorage.getItem('query') === null) {
			history.push('/');
			return;
		}
		if (secureLocalStorage.getItem('selectedAccount')) {
			history.push('/dashboard');
			return;
		}
		fetchCustomerAccounts();

		return () => {
			setSelectedItem(null);
		};
	}, []);

	return (
		<PageWrapper title={authMenu.selectAccount.text} bgcolor={Colors.White}>
			<Page className='d-flex justify-content-center align-items-center m-0 p-0 py-sm-5 vh-100'>
				<div
					className='d-flex flex-column justify-content-between col-12 col-sm-8 col-lg-6 p-3 rounded shadow bg-white'
					style={{ minHeight: '100%' }}>
					<LoginHeader />
					<div className='d-flex col-12 align-items-center flex-column'>
						<Logo />
						<div className='d-flex flex-column align-items-center mt-3'>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '22px',
									color: Colors.Black,
								}}>
								{t('select.device.title')}
							</span>
							<span
								style={{
									textAlign: 'center',
									fontWeight: '400',
									fontSize: '15px',
									color: Colors.Black,
									marginTop: '10px',
								}}>
								{t('select.device.desc')}
							</span>
						</div>
						<div className='d-flex align-items-center justify-content-center flex-wrap gap-4 my-5'>
							{loading ? (
								<Loader color='green' />
							) : (
								userAccounts.map((item, index) => {
									return (
										<div
											key={index}
											onKeyPress={() => {}}
											role='button'
											tabIndex='0'
											onClick={() => handleItemClick(item, index)}
											className='d-flex flex-column p-3
										rounded'
											style={{
												minWidth: '300px',
												background:
													selectedItem?.index === index
														? Colors.Brand
														: Colors.White,
												boxShadow:
													'rgba(50, 50, 93, 0.25) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
											}}>
											{Object.entries(item)
												.slice(1)
												.map(([key, value]) => (
													<div
														className='d-flex justify-content-between gap-5'
														key={key}>
														<span
															style={{
																fontSize: '13px',
																fontWeight: 'bold',
															}}>
															{key.includes('_')
																? key.split('_').join(' ')
																: key}
														</span>

														<p>{value}</p>
													</div>
												))}
										</div>
									);
								})
							)}
						</div>
						<button
							disabled={selectedItem === null}
							style={{
								background: Colors.Brand,
								borderRadius: '12px',
								color: Colors.White,
							}}
							onClick={handleContinue}
							type='submit'
							className='btn py-3 col-sm-10 col-md-8 col-10 mt-4'>
							{t('login.continue')}
						</button>
					</div>

					<LoginFooter />
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SelectAccount;
