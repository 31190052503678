import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activeStep: 0,
	dispatched: [
		{
			step: 1,
			title: 'Personal Details',
			status: 'pending',
			reason: null,
		},
		{
			step: 2,
			title: 'Device Details',
			status: 'pending',
			reason: null,
		},
		{
			step: 3,
			title: 'Farm Details',
			status: 'pending',
			reason: null,
		},
		{
			step: 4,
			title: 'Submitted Status',
			status: 'pending',
			reason: 'Kindly submit or resubmit the pending KYC info',
		},
	],
	firstSubmit: true,
	personalDetails: {
		status: 'pending',
		accountId: '',
		stateId: '',
		stateName: '',
		subStateId: '',
		subStateName: '',
		frontIdImage: null,
		backIdImage: null,
		passportPhoto: null,
		rejectedMessage: null,
	},
	farmDetails: {
		status: 'pending',
		accountId: '',
		farmManagement: '',
		farmOwnership: '',
		hasWater: '',
		currentWaterSource: '',
		farmImage: null,
		pumpImage: null,
		waterSourceImage: null,
		lat: '',
		log: '',
		rejectedMessage: null,
	},
	deviceDetails: {
		status: 'pending',
		deviceid: '',
		accountId: '',
		paygContractNumber: '',
		deviceIdImage: null,
		signedContractImage: [],
		warrantCardImage: null,
		rejectedMessage: null,
	},
	registrationStatus: 'pending',
	personalDataChanged: false,
	farmDataChanged: false,
	deviceDataChanged: false,
	imageLoading: false,
	isPersonalDetailsEditable: false,
	isFarmDetailsEditable: false,
	isDeviceDetailsEditable: false,
};

const SelfRegisterStepperSlice = createSlice({
	name: 'selfRegisterStepper',
	initialState,
	reducers: {
		setActiveStep: (state, action) => {
			state.activeStep = action.payload;
		},
		incrementStep: (state) => {
			state.activeStep += 1;
		},
		decrementStep: (state) => {
			state.activeStep -= 1;
		},
		setDispatched: (state, action) => {
			state.dispatched = action.payload;
		},
		setFirstSubmit: (state, action) => {
			state.firstSubmit = action.payload;
		},
		setPersonalDetails: (state, action) => {
			state.personalDetails = action.payload;
		},
		setFarmDetails: (state, action) => {
			state.farmDetails = action.payload;
		},
		setDeviceDetails: (state, action) => {
			state.deviceDetails = action.payload;
		},
		setregistrationStatus: (state, action) => {
			state.registrationStatus = action.payload;
		},
		setPersonalDataChanged: (state, action) => {
			state.personalDataChanged = action.payload;
		},
		setFarmDataChanged: (state, action) => {
			state.farmDataChanged = action.payload;
		},
		setDeviceDataChanged: (state, action) => {
			state.deviceDataChanged = action.payload;
		},
		setImageLoading: (state, action) => {
			state.imageLoading = action.payload;
		},
		setIsPersonalDetailsEditeble: (state, action) => {
			state.isPersonalDetailsEditable = action.payload;
		},
		setIsFarmDetailsEditeble: (state, action) => {
			state.isFarmDetailsEditable = action.payload;
		},
		setIsDeviceDetailsEditeble: (state, action) => {
			state.isDeviceDetailsEditable = action.payload;
		},
	},
});

const { actions, reducer } = SelfRegisterStepperSlice;

export const {
	setActiveStep,
	incrementStep,
	decrementStep,
	setDispatched,
	setFirstSubmit,
	setPersonalDetails,
	setFarmDetails,
	setDeviceDetails,
	setregistrationStatus,
	setPersonalDataChanged,
	setFarmDataChanged,
	setDeviceDataChanged,
	setImageLoading,
	setIsPersonalDetailsEditeble,
	setIsFarmDetailsEditeble,
	setIsDeviceDetailsEditeble,
} = actions;
export default reducer;
