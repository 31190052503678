import React from 'react';

const Loader = ({ color, size }) => {
	return (
		<div
			className='spinner-border'
			style={{
				width: size || '3rem',
				height: size || '3rem',
				color: color || 'black',
			}}
			role='status'
		/>
	);
};

export default Loader;
