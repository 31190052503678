import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

const Logout = () => {
	const { t } = useTranslation();

	useEffect(() => {
		if (window.confirm(t('confirm.logout'))) {
			secureLocalStorage.clear();
			window.location.reload();
		}
	});

	return <Redirect to='/dashboard' />;
};

export default Logout;
