import { useState, useRef, useEffect } from 'react';

const OtpTimer = (props) => {
	const { setShow } = props;
	const [timer, setTimer] = useState('00:00:00');
	const Ref = useRef(null);

	// cleartime and set the ticking clocking to update every second
	const clearTimer = (e) => {
		setTimer('00:20:00');
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000);
		Ref.current = id;
	};

	// Get the 20 seconds from the current time to allow us to track the timer properly
	const getDeadTime = () => {
		const deadline = new Date();
		deadline.setMinutes(deadline.getMinutes() + 20);
		deadline.setSeconds(deadline.getSeconds() + 0);
		return deadline;
	};

	// initiate the timer and get the remaining time
	const startTimer = (e) => {
		const { total, hours, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			setTimer(
				`${hours > 9 ? hours : `0${hours}`}:${
					minutes > 9 ? minutes : `0${minutes}`
				}:${seconds > 9 ? seconds : `0${seconds}`}`,
			);
		} else {
			setShow(false);
		}
	};

	// Get the remaining time from DeadTime(20 seconds)
	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total,
			hours,
			minutes,
			seconds,
		};
	};

	useEffect(() => {
		clearTimer(getDeadTime());

		return () => {
			clearTimer();
		};
	}, []);

	return timer;
};

export default OtpTimer;
