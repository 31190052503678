import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import Logo from '../../components/Logo/Logo';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import axiosInstance from '../../services/axiosInstance';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { authMenu } from '../../helpers/menu';
import { validatePhoneInput } from '../../helpers/helpers';
import { productOfInterest, waterSources } from '../../helpers/options';
import LoginHeader from '../Login/LoginHeader';
import LoginFooter from '../Login/LoginFooter';
import Colors from '../../helpers/AppColors';

function SignUpForm() {
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [location, setLocation] = useState('');
	const [locationError, setLocationError] = useState('');
	const [watersource, setWaterSource] = useState('');
	const [product, setProduct] = useState('');
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();

	function submitHandler(e) {
		e.preventDefault();
		setLoading(true);
		const payload = {
			firstName,
			lastName,
			mobilePhone: phone,
			referralName: 'null',
			referralPhoneNumber: 'null',
			waterSource: watersource,
			location,
			productInterested: product,
			leadSource: 'Customer Portal',
		};
		if (window.confirm(i18n.t('signup.confirm', { firstName }))) {
			axiosInstance
				.post(`v1/newCustomer/salesforce`, payload)
				.then((response) => {
					if (response.data.headers.status === true) {
						toast.success(i18n.t('signup.sucess'));
						window.location.reload();
					} else {
						setLoading(false);
						toast.error(t('signup.resource.failed'));
						clearTextFields();
					}
				})
				.catch(() => {
					setLoading(false);
					toast.error(t('signup.resource.failed'));
					clearTextFields();
				});
		}
	}

	const clearTextFields = () => {
		setFirstName('');
		setPhone('');
		setLastName('');
		setLocation('');
	};

	return (
		<PageWrapper title={authMenu.signup.text} bgcolor={Colors.White}>
			<Page className='d-flex justify-content-center align-items-center m-0 p-0 py-sm-4 vh-100'>
				<div
					style={{ minHeight: '100%' }}
					className='d-flex bg-white flex-column  justify-content-between col-12 col-sm-8 col-lg-6 py-3 px-4 gap-5 rounded shadow'>
					<LoginHeader page='SignUP' />
					<div className='d-flex col-12 align-items-center flex-column'>
						<Logo />
						<div className='d-flex flex-column align-items-center my-2 '>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '22px',
									color: Colors.Black,
								}}>
								{t('signup.title')}
							</span>
						</div>
						<form onSubmit={submitHandler} className='col-12 col-lg-10 mt-4'>
							<div className='row'>
								<div className='col-md-6 mb-4'>
									<FormGroup
										id='firstName'
										name='firstName'
										isFloating
										formText={
											<div style={{ color: 'red' }}>{firstNameError}</div>
										}
										label={`${t('signup.form.firstName')}*`}>
										<Input
											value={firstName}
											type='text'
											onChange={(e) => {
												if (e.target.value.length === 0) {
													setFirstNameError(
														i18n.t('signup.error.msg.firstname'),
													);
												} else {
													setFirstNameError('');
												}
												setFirstName(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 mb-4'>
									<FormGroup
										id='lastName'
										name='lastName'
										formText={
											<div style={{ color: 'red' }}>{lastNameError}</div>
										}
										isFloating
										label={`${t('signup.form.lastName')}*`}>
										<Input
											value={lastName}
											type='text'
											onChange={(e) => {
												if (e.target.value.length === 0) {
													setLastNameError(i18n.t('signup.error.msg.lastname'));
												} else {
													setLastNameError('');
												}
												setLastName(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 mb-4'>
									<FormGroup
										id='phone'
										name='Phone'
										formText={<div style={{ color: 'red' }}>{phoneError}</div>}
										isFloating
										label={`${t('signup.form.phoneNumber')}*`}>
										<Input
											min={null}
											value={phone}
											type='number'
											onChange={(e) => {
												if (e.target.value === '') {
													setPhoneError(i18n.t('phone-input-digits'));
												} else if (!validatePhoneInput(e.target.value)) {
													setPhoneError(i18n.t('signup.error.msg.phonenumber'));
												} else {
													setPhoneError('');
												}
												setPhone(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 mb-4'>
									<FormGroup
										id='location'
										name='location'
										formText={
											<div style={{ color: 'red' }}>{locationError}</div>
										}
										isFloating
										label={`${t('label.location')}*`}>
										<Input
											value={location}
											type='text'
											onChange={(e) => {
												if (e.target.value.length === 0) {
													setLocationError(i18n.t('signup.error.msg.location'));
												} else {
													setLocationError('');
												}
												setLocation(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 mb-4'>
									<FormGroup id='water_source' name='water_source'>
										<Select
											className={`py-3 ${!watersource ? 'fw-light' : ''} `}
											firstName='water_source'
											value={watersource}
											onChange={(e) => {
												setWaterSource(e.target.value);
											}}>
											<option value='' disabled>
												{`${t('label.select.watersource')}*`}
											</option>
											{waterSources.map((d, i) => {
												return (
													<option key={i} value={d.key}>
														{d.value}
													</option>
												);
											})}
										</Select>
									</FormGroup>
								</div>
								<div className='col-md-6 mb-4'>
									<FormGroup>
										<Select
											className={`py-3 ${!product ? 'fw-light' : ''} `}
											firstName='PROD'
											value={product}
											onChange={(e) => setProduct(e.target.value)}>
											<option value='' disabled>
												{`${t('label.select.product')}*`}
											</option>
											{productOfInterest.map((d) => {
												return <option value={d.value}>{d.value}</option>;
											})}
										</Select>
									</FormGroup>
								</div>
							</div>

							<div className='d-flex align-items-center justify-content-center my-3'>
								<button
									disabled={
										lastName.length <= 1 ||
										firstName.length <= 1 ||
										!validatePhoneInput(phone) ||
										location.length <= 1 ||
										watersource === ''
									}
									style={{
										background: Colors.Brand,
										borderRadius: '12px',
										color: Colors.White,
									}}
									type='submit'
									className='btn col-sm-10 col-md-8 col-12 py-3 mt-3'
									data-testid='Submit'>
									{loading ? (
										<div
											className='spinner-border spinner-border-sm text-light '
											role='status'>
											<span className='visually-hidden'>Loading...</span>
										</div>
									) : (
										t('signup.submit')
									)}
								</button>
							</div>
						</form>
						<div
							className='d-flex align-items-center justify-content-center mt-2 p-2'
							style={{
								color: Colors.Black,
							}}>
							{t('login.account.already')}
							<Link
								to='/'
								style={{
									marginLeft: '3px',
									fontWeight: 600,
									outline: 'none',
									color: Colors.Green,
									textDecoration: 'none',
								}}>
								{t('login.here')}
							</Link>
						</div>
					</div>
					<LoginFooter />
				</div>
			</Page>
		</PageWrapper>
	);
}

export default SignUpForm;
