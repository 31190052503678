import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';

const Brand = () => {
	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Link to='/dashboard' aria-label='Logo' alt='Sun'>
						<Logo />
					</Link>
				</h1>
			</div>
		</div>
	);
};

export default Brand;
