import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pointsData: [],
	totalPoints: null,
	topUsers: [],
};

const productivitySlice = createSlice({
	name: 'productivity',
	initialState,
	reducers: {
		setPointsData: (state, action) => {
			state.pointsData = action.payload;
		},
		setTotalPoints: (state, action) => {
			state.totalPoints = action.payload;
		},
		setTopUsers: (state, action) => {
			state.topUsers = action.payload;
		},
	},
});

const { reducer, actions } = productivitySlice;

export const { setPointsData, setTotalPoints, setTopUsers } = actions;

export default reducer;
