import { combineReducers, configureStore } from '@reduxjs/toolkit';
import modalsReducer from './slices/Modals/modelSlices';
import soiltTestReducer from './slices/SoilTest/soilTestSlice';
import deviceReducer from './slices/Device/DeviceSlice';
import accountReducer from './slices/Account/accountSlice';
import weatherReducer from './slices/Weather/WeatherSlice';
import productivityReducer from './slices/Productivity/productivitySlice';
import selfRegisterStepperReducer from './slices/SelfRegisterStepper/SelfRegisterStepperSlice';

const reducer = combineReducers({
	device: deviceReducer,
	soilTest: soiltTestReducer,
	account: accountReducer,
	modals: modalsReducer,
	weather: weatherReducer,
	productivity: productivityReducer,
	selfRegisterStepper: selfRegisterStepperReducer,
});

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: true,
});

export default store;
