import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import Colors from '../../helpers/AppColors';
import { setIsmultiAccountModal } from '../../slices/Modals/modelSlices';
import Modal, { ModalBody } from '../bootstrap/Modal';
import {
	setAccountRef,
	setSelectedAccount,
} from '../../slices/Account/accountSlice';
import { setDeviceData, setIsVerified } from '../../slices/Device/DeviceSlice';

const MultiAccountModal = ({ accountsDetails }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isMultiAccountModal } = useSelector((state) => state.modals);

	const selected = secureLocalStorage.getItem('selectedAccount');

	const [selectedItem, setSelectedItem] = React.useState(null);

	const handleItemClick = (item, index) => {
		if (selectedItem === null) {
			setSelectedItem({ item, index });
		} else {
			setSelectedItem({ item, index });
		}
	};

	React.useEffect(() => {
		accountsDetails.accounts.map((item, index) => {
			if (item.id === selected) {
				setSelectedItem({ item, index });
			}
		});
	}, [isMultiAccountModal]);

	const handleContinue = () => {
		secureLocalStorage.setItem('selectedAccount', selectedItem.item.id);
		secureLocalStorage.removeItem('deviceID');
		dispatch(setDeviceData({}));
		dispatch(setIsVerified(false));
		dispatch(setSelectedAccount(selectedItem.item.id));
		dispatch(setAccountRef(selectedItem.item.Account_Ref));
		dispatch(setIsmultiAccountModal(!isMultiAccountModal));
	};

	const handleLogout = () => {
		if (window.confirm(t('confirm.logout'))) {
			secureLocalStorage.clear();
			window.location.reload();
		}
	};

	return (
		<Modal isOpen={isMultiAccountModal} isCentered isStaticBackdrop>
			<ModalBody className='mb-4'>
				<div
					className={`${
						isMultiAccountModal === undefined && 'shadow-sm rounded'
					} d-flex px-2 px-sm-3 py-3 flex-column align-items-center justify-content-center gap-2`}>
					<div className='d-flex align-items-center col-12 mb-3'>
						<span
							className='flex-fill text-center'
							style={{ fontWeight: '600', fontSize: '20px' }}>
							{t('label.select.account')}
						</span>
						{isMultiAccountModal && (
							<button
								onClick={() =>
									dispatch(setIsmultiAccountModal(!isMultiAccountModal))
								}
								type='button'
								className='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'
							/>
						)}
					</div>
					<div className='d-flex align-items-center justify-content-center flex-wrap gap-4 my-5'>
						{accountsDetails &&
							accountsDetails.accounts?.map((account, index) => {
								return (
									<div
										key={index}
										onKeyPress={() => {}}
										role='button'
										tabIndex='0'
										onClick={() => handleItemClick(account, index)}
										className='d-flex flex-column p-3
										rounded'
										style={{
											minWidth: '300px',
											background:
												selectedItem?.index === index
													? Colors.Brand
													: Colors.White,
											boxShadow:
												'rgba(50, 50, 93, 0.25) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
										}}>
										{Object.entries(account)
											.slice(1)
											.map(([key, value]) => (
												<div
													className='d-flex justify-content-between gap-5'
													key={key}>
													<span
														style={{
															fontSize: '13px',
															fontWeight: 'bold',
														}}>
														{key.includes('_') ? key.split('_').join(' ') : key}
													</span>

													<p>{value}</p>
												</div>
											))}
									</div>
								);
							})}
					</div>

					<button
						type='button'
						disabled={selectedItem === null}
						className='btn'
						onClick={handleContinue}
						style={{
							background: Colors.Brand,
							width: '80%',
							borderRadius: '12px',
							outline: 'none',
							border: 'none',
							fontSize: '1rem',
							color: 'white',
							padding: '.7rem 2rem',
						}}>
						{t('login.continue')}
					</button>
					<button
						type='button'
						className='btn'
						onClick={handleLogout}
						style={{
							background: Colors.pank,
							width: '80%',
							borderRadius: '12px',
							outline: 'none',
							border: 'none',
							fontSize: '1rem',
							color: 'white',
							padding: '.7rem 2rem',
						}}>
						{t('menu.logout')}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default MultiAccountModal;
