import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import DashboardHeader from '../../components/Header/DashboardHeader';
import MainHeader from '../../components/Header/MainHeader';
import { dashboardMenu } from '../../helpers/menu';

const HeaderRoutes = () => {
	const location = useLocation();

	return (
		<Switch location={location}>
			<Route
				path={[dashboardMenu.dashboard.path]}
				exact
				render={(props) => (
					<MainHeader
						headerComp={<DashboardHeader />}
						title='header.dashboard'
						{...props}
						showBanner
					/>
				)}
			/>
			<Route
				path={[dashboardMenu.accountInfo.path]}
				exact
				render={(props) => (
					<MainHeader title='header.account' {...props} showBanner />
				)}
			/>
			<Route
				path={[dashboardMenu.verifyDevice.path]}
				exact
				render={(props) => <MainHeader title='header.device' {...props} />}
			/>
			<Route
				path={dashboardMenu.referrals.path}
				exact
				render={(props) => <MainHeader title='header.referral' {...props} />}
			/>
			<Route
				path={[dashboardMenu.tickets.path]}
				exact
				render={(props) => <MainHeader title='header.ticket' {...props} />}
				showBanner
			/>
		</Switch>
	);
};

export default HeaderRoutes;
