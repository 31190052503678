import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isVerified: false,
	deviceId: '',
	selectedDevice: null,
	deviceData: {},
	graphLabels: [],
	graphDatas: [],
};

const deviceSlice = createSlice({
	name: 'device',
	initialState,
	reducers: {
		setSelectedDevice: (state, action) => {
			state.selectedDevice = action.payload;
		},
		setIsVerified: (state, action) => {
			state.isVerified = action.payload;
		},
		setDeviceId: (state, action) => {
			state.deviceId = action.payload;
		},
		setDeviceData: (state, action) => {
			state.deviceData = action.payload;
		},
		setGraphLabels: (state, action) => {
			state.graphLabels = action.payload;
		},
		setGraphDatas: (state, action) => {
			state.graphDatas = action.payload;
		},
	},
});

const { reducer, actions } = deviceSlice;

export const {
	setSelectedDevice,
	setIsVerified,
	setDeviceId,
	setDeviceData,
	setGraphLabels,
	setGraphDatas,
} = actions;

export default reducer;
