import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import Header from '../../layout/Header/Header';
import MultiAccountModal from '../Modals/MultiAccountModal';
import Icon from '../icon/Icon';
import { setIsmultiAccountModal } from '../../slices/Modals/modelSlices';

const MainHeader = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isMultiAccountModal } = useSelector((state) => state.modals);
	const { accountRef } = useSelector((state) => state.account);
	const accounts = JSON.parse(secureLocalStorage.getItem('userDetails'));

	return (
		<Header>
			<div
				className='d-flex justify-content-center align-items-center'
				style={{ flexDirection: window.innerWidth <= 700 ? 'column' : 'row' }}>
				{props.headerComp ? (
					props.headerComp
				) : (
					<span className='fs-4 flex-fill text-center d-flex fw-normal text-white justify-content-center '>
						{t(props.title)}
					</span>
				)}
				{accounts.accounts.length > 1 && (
					<button
						type='button'
						onClick={() =>
							dispatch(setIsmultiAccountModal(!isMultiAccountModal))
						}
						className='d-flex border-0 bg-transparent justify-content-center align-items-center gap-3 text-white'>
						{accountRef}
						<Icon size='2x' icon='arrow_drop_down' />
					</button>
				)}
			</div>
			<MultiAccountModal accountsDetails={accounts} />
		</Header>
	);
};

export default MainHeader;
