import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { Logout, dashboardMenu } from '../../helpers/menu';
import axiosInstance from '../../services/axiosInstance';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';

const Aside = () => {
	const location = useLocation();
	const nationalId = secureLocalStorage.getItem('query');

	useEffect(() => {
		axios.get('https://ipapi.co/json/').then((res) => {
			axiosInstance.post('v1/gps-logger', {
				accountNumber: nationalId || '0',
				countryName: res.data.country_name ? res.data.country_name : 'null',
				city: res.data.region ? res.data.region : 'null',
				region: res.data.region ? res.data.region : 'null',
				latitude: res.data.latitude ? res.data.latitude.toString() : 0.0,
				longitude: res.data.longitude ? res.data.longitude.toString() : 0.0,
			});
		});
	}, [location]);

	return (
		<div
			className='d-flex flex-column vh-sm-100 px-0 px-sm-3'
			style={
				window.innerWidth <= 567
					? {
							position: 'sticky',
							backgroundColor: 'white',
							zIndex: 100,
							width: '100%',
							bottom: 0,
					  }
					: {
							position: 'sticky',
					  }
			}>
			<div className='aside-head d-none d-sm-block'>
				<Brand />
			</div>
			<div className='aside-body'>
				<Navigation menu={dashboardMenu} id='aside-dashboard' />
			</div>
			<div className='aside-footer aside-head d-none d-sm-block'>
				<Navigation menu={Logout} id='aside-dashboard' />
			</div>
		</div>
	);
};

export default Aside;
