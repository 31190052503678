import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isMpesaModal: false,
	isMultiDeviceModal: false,
	isSelfRegisterModal: false,
	isVerifyDeviceModal: false,
	isMultiAccountModal: false,
	isInstallmentinfoModal: false,
	isCameraModal: { isOpen: false, imageState: '' },
};

const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		setIsMpesaModal: (state, action) => {
			state.isMpesaModal = action.payload;
		},
		setIsMultiDeviceModal: (state, action) => {
			state.isMultiDeviceModal = action.payload;
		},
		setIsSelfRegisterModal: (state, action) => {
			state.isSelfRegisterModal = action.payload;
		},
		setIsVerifyDeviceModal: (state, action) => {
			state.isVerifyDeviceModal = action.payload;
		},
		setIsmultiAccountModal: (state, action) => {
			state.isMultiAccountModal = action.payload;
		},
		setIsInstallmentinfoModal: (state, action) => {
			state.isInstallmentinfoModal = action.payload;
		},
		setIsCameraModal: (state, action) => {
			state.isCameraModal = action.payload;
		},
	},
});

const { reducer, actions } = modalsSlice;

export const {
	setIsMpesaModal,
	setIsMultiDeviceModal,
	setIsSelfRegisterModal,
	setIsVerifyDeviceModal,
	setIsmultiAccountModal,
	setIsInstallmentinfoModal,
	setIsCameraModal,
} = actions;

export default reducer;
