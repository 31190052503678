import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import secureLocalStorage from 'react-secure-storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = async (setTokenFound) => {
	try {
		const currentToken = await getToken(messaging, {
			vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
		});
		if (currentToken) {
			secureLocalStorage.setItem('firebaseToken', currentToken);
			setTokenFound(true);
		} else {
			// eslint-disable-next-line no-console
			console.error(
				'No registration token available. Request permission to generate one.',
			);
			secureLocalStorage.setItem('firebaseToken', 'NULL');
			setTokenFound(false);
		}
	} catch (err) {
		secureLocalStorage.setItem('firebaseToken', 'NULL');
	}
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
